import { createPortfolioAdapter } from '@hellodarwin/core/lib/features/adapters/portfolio-adapter';
import { createPortfolioAssetsAdapter } from '@hellodarwin/core/lib/features/adapters/portfolio-assets-adapter';
import {
  ApiError,
  ApiErrorInitialState,
  Company,
  CompanyAsset,
  CompanyInitialState,
  Contact,
  ContactInitialState,
  MappedPortfolioAssets,
  Portfolio,
  Provider,
  ProviderInitialState,
  ReviewResult,
  Rfp,
  ServiceTag,
  TeamMember,
  TeamMemberItem,
} from '@hellodarwin/core/lib/features/entities';
import { PartnerRfpRequest } from '@hellodarwin/core/lib/features/entities/rfp-entities';
import { UTMParamsType } from '@hellodarwin/core/lib/features/providers/utm-provider';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import convertProviderToProfile from '../../helpers/convert-provider-profile';
import showErrorNotification from '../../helpers/show-error-notifications';
import { createTeamMemberAdapter } from '../adapters/team-adapter';
import PartnerApiClient from '../partner-api-client';

//Adapters

const teamMemberAdapter = createEntityAdapter({
  selectId: (model: TeamMember) => model.provider_team_member_id,
});
const portfolioAdapter = createPortfolioAdapter();
const portfolioAssetsAdapter = createPortfolioAssetsAdapter();

const reviewsAdapter = createEntityAdapter({
  selectId: (model: ReviewResult) => model.review_id,
});
const usersAdapter = createEntityAdapter({
  selectId: (model: Contact) => model.contact_id,
});

const teamMemberOrderAdapter = createTeamMemberAdapter();

//State
export interface ProfileState {
  status: {
    hello: 'idle' | 'pending';
    general: 'idle' | 'pending';
    assets: 'idle' | 'pending';
  };
  error: ApiError;
  providerProfile: Provider;
  portfolios: { [key: string]: Portfolio };
  portfoliosAssets: { [key: string]: MappedPortfolioAssets };
  teamMembers: EntityState<TeamMember, string>;
  reviews: EntityState<ReviewResult, string>;
  company: Company;
  contact: Contact;
  users: EntityState<Contact, string>;
}

const initialState: ProfileState = {
  status: {
    hello: 'idle',
    general: 'idle',
    assets: 'idle',
  },
  error: ApiErrorInitialState,
  providerProfile: ProviderInitialState,
  portfolios: portfolioAdapter.getInitialState(),
  portfoliosAssets: portfolioAssetsAdapter.getInitialState(),
  teamMembers: teamMemberAdapter.getInitialState(),
  reviews: reviewsAdapter.getInitialState(),
  company: CompanyInitialState,
  contact: ContactInitialState,
  users: usersAdapter.getInitialState(),
};

export const hello = createAsyncThunk<
  Provider,
  {
    api: PartnerApiClient;
    sb_promocode: string | null;
    utm: UTMParamsType | null;
  },
  { rejectValue: ApiError }
>(
  'partner/hello',
  async (
    {
      api,
      sb_promocode,
      utm,
    }: {
      api: PartnerApiClient;
      sb_promocode: string | null;
      utm: UTMParamsType | null;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.hello(sb_promocode, utm);
    } catch (err: any) {
      console.error(err.response.data);
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createTags = createAsyncThunk<
  { response: string },
  { api: PartnerApiClient; serviceTags: ServiceTag[]; specialtyTags: string[] },
  { rejectValue: ApiError }
>(
  'partner/createTags',
  async (
    {
      api,
      serviceTags,
      specialtyTags,
    }: {
      api: PartnerApiClient;
      serviceTags: ServiceTag[];
      specialtyTags: string[];
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.createTags(serviceTags, specialtyTags);
      return { response };
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const onboard = createAsyncThunk<
  string,
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/onboard',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.onboard();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const onboardingMatch = createAsyncThunk<
  string,
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/onboard/matches',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.onboardingMatch();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfile = createAsyncThunk<
  Provider,
  { api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updateProfile',
  async (
    { api, data }: { api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateProfile(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateLogo = createAsyncThunk<
  Provider,
  { api: PartnerApiClient; companyId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updateLogo',
  async (
    {
      api,
      companyId,
      data,
    }: { api: PartnerApiClient; companyId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateLogo(companyId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteLogo = createAsyncThunk<
  Provider,
  { api: PartnerApiClient; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/deleteLogo',
  async (
    { api, companyId }: { api: PartnerApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteLogo(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateBanner = createAsyncThunk<
  Provider,
  { api: PartnerApiClient; companyId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updateBanner',
  async (
    {
      api,
      companyId,
      data,
    }: { api: PartnerApiClient; companyId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateBanner(companyId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteBanner = createAsyncThunk<
  Provider,
  { api: PartnerApiClient; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/deleteBanner',
  async (
    { api, companyId }: { api: PartnerApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteBanner(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);
export const updateVideos = createAsyncThunk<
  CompanyAsset,
  { api: PartnerApiClient; companyId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updateVideos',
  async (
    {
      api,
      companyId,
      data,
    }: { api: PartnerApiClient; companyId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateVideos(companyId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteVideos = createAsyncThunk<
  Provider,
  { api: PartnerApiClient; companyId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/deleteVideos',
  async (
    { api, companyId }: { api: PartnerApiClient; companyId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteVideos(companyId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchPortfolios = createAsyncThunk<
  Portfolio[],
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/fetchPortfolios',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchPortfolios();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const submitPortfolio = createAsyncThunk<
  Portfolio,
  { api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/submitPortfolio',
  async (
    { api, data }: { api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.submitPortfolio(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);

export const removePortfolio = createAsyncThunk<
  string,
  { api: PartnerApiClient; portfolioID: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/removePortfolio',
  async (
    { api, portfolioID }: { api: PartnerApiClient; portfolioID: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.removePortfolio(portfolioID);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);

export const updatePortfolio = createAsyncThunk<
  Portfolio,
  { api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updatePortfolio',
  async (
    { api, data }: { api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updatePortfolio(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);

export const fetchTeamMembers = createAsyncThunk<
  TeamMember[],
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/fetchTeamMembers',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchTeamMembers();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchReviewsById = createAsyncThunk<
  ReviewResult[],
  { api: PartnerApiClient; provider_id: string },
  { rejectValue: ApiError }
>(
  'partner/fetchReviewsById',
  async (
    { api, provider_id }: { api: PartnerApiClient; provider_id: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchReviewsById(provider_id);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const submitTeamMember = createAsyncThunk<
  TeamMember,
  { api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/submitTeamMember',
  async (
    { api, data }: { api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.submitTeamMember(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);
export const editTeamMember = createAsyncThunk<
  TeamMember,
  { teamMemberId: string; api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/editTeamMember',
  async (
    {
      teamMemberId,
      api,
      data,
    }: { teamMemberId: string; api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.editTeamMember(teamMemberId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);
export const editTeamMemberOrder = createAsyncThunk<
  TeamMemberItem[],
  { api: PartnerApiClient; teamMembers: TeamMemberItem[] },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/editTeamMemberOrder',
  async (
    {
      teamMembers,
      api,
    }: {
      teamMembers: TeamMemberItem[];
      api: PartnerApiClient;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateTeamMemberOrder(teamMembers);
    } catch (err: any) {
      showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeTeamMember = createAsyncThunk<
  string,
  { api: PartnerApiClient; teamMemberID: string },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/removeTeamMember',
  async (
    { api, teamMemberID }: { api: PartnerApiClient; teamMemberID: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.removeTeamMember(teamMemberID);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);

export const submitReferredRfp = createAsyncThunk<
  Rfp,
  { api: PartnerApiClient; rfp: PartnerRfpRequest },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/submitReferredRfp',
  async (
    { api, rfp }: { api: PartnerApiClient; rfp: PartnerRfpRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.submitReferralRfp(rfp);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);

export const fetchProfile = createAsyncThunk<
  Provider,
  { api: PartnerApiClient },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/fetchProfile',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchProfile();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { profile } = getState();
      if (profile.status.general === 'pending') return false;
    },
  },
);

export const fetchContact = createAsyncThunk<
  Contact,
  { api: PartnerApiClient },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/fetchContact',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchContact();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);
export const updateContactById = createAsyncThunk<
  Contact,
  { api: PartnerApiClient; contactId: string; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updateContactById',
  async (
    {
      api,
      contactId,
      data,
    }: { api: PartnerApiClient; contactId: string; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateContactById(contactId, data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);
export const updateContact = createAsyncThunk<
  Contact,
  { api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updateContact',
  async (
    { api, data }: { api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateContact(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const fetchCompany = createAsyncThunk<
  Company,
  { api: PartnerApiClient },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/fetchCompany',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchCompany();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const updateCompany = createAsyncThunk<
  Company,
  { api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/updateCompany',
  async (
    { api, data }: { api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.updateCompany(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const fetchCompanyUsers = createAsyncThunk<
  Contact[],
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'admin/fetchCompanyUsers',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchCompanyUsers();
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);
export const deleteCompanyUser = createAsyncThunk<
  string,
  { api: PartnerApiClient; contactID: string },
  { rejectValue: ApiError }
>(
  'admin/deleteCompanyUser',
  async (
    { api, contactID }: { api: PartnerApiClient; contactID: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.deleteCompanyUser(contactID);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const upsertCompanyUser = createAsyncThunk<
  Contact,
  { api: PartnerApiClient; data: FormData },
  { rejectValue: ApiError; state: RootState }
>(
  'client/upsertCompanyUser',
  async (
    { api, data }: { api: PartnerApiClient; data: FormData },
    { rejectWithValue },
  ) => {
    try {
      return await api.upsertCompanyUser(data);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { global } = getState();
      if (global.status === 'pending') return false;
    },
  },
);

export const sendInvitationEmail = createAsyncThunk<
  Contact,
  { api: PartnerApiClient; email: string },
  { rejectValue: ApiError; state: RootState }
>(
  'client/sendInvitationEmail',
  async (
    { api, email }: { api: PartnerApiClient; email: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.sendInvitationEmail(email);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getContactByEmail = createAsyncThunk<
  Contact,
  { api: PartnerApiClient; email: string },
  { rejectValue: ApiError; state: RootState }
>(
  'client/getContactByEmail',
  async (
    { api, email }: { api: PartnerApiClient; email: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getContactByEmail(email);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(hello.pending, (state) => {
      state.status.hello = 'pending';
    });
    builder.addCase(hello.fulfilled, (state, { payload }) => {
      state.providerProfile = payload;
      state.status.hello = 'idle';
    });
    builder.addCase(hello.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.hello = 'idle';
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      const { tagline, phone } = payload;
      state.providerProfile = { ...state.providerProfile, ...payload };

      //shitfix to update form
      state.company = { ...state.company, tagline, phone };
      state.status.general = 'idle';
    });
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(fetchPortfolios.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(fetchPortfolios.fulfilled, (state, { payload }) => {
      state.portfolios = portfolioAdapter.setAll(state.portfolios, payload);

      state.status.general = 'idle';
    });
    builder.addCase(fetchPortfolios.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });

    builder.addCase(submitPortfolio.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(submitPortfolio.fulfilled, (state, { payload }) => {
      state.portfolios = portfolioAdapter.insertOneAtStart(
        state.portfolios,
        payload,
      );

      state.status.general = 'idle';
    });
    builder.addCase(submitPortfolio.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });

    builder.addCase(removePortfolio.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(removePortfolio.fulfilled, (state, { payload }) => {
      state.portfolios = portfolioAdapter.removeOne(state.portfolios, payload);
      state.status.general = 'idle';
    });
    builder.addCase(removePortfolio.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(updatePortfolio.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updatePortfolio.fulfilled, (state, { payload }) => {
      state.portfolios = portfolioAdapter.updateOne(state.portfolios, {
        id: payload.provider_portfolio_id || '',
        changes: payload,
      });
      state.status.general = 'idle';
    });
    builder.addCase(updatePortfolio.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });

    builder.addCase(fetchTeamMembers.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(fetchTeamMembers.fulfilled, (state, { payload }) => {
      teamMemberAdapter.setAll(state.teamMembers, payload);
      state.status.general = 'idle';
    });
    builder.addCase(fetchTeamMembers.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(fetchReviewsById.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(fetchReviewsById.fulfilled, (state, { payload }) => {
      if (!!payload) {
        reviewsAdapter.setAll(state.reviews, payload);
      }
      state.status.general = 'idle';
    });
    builder.addCase(fetchReviewsById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(submitTeamMember.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(submitTeamMember.fulfilled, (state, { payload }) => {
      teamMemberAdapter.addOne(state.teamMembers, payload);
      state.status.general = 'idle';
    });
    builder.addCase(submitTeamMember.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(editTeamMember.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(editTeamMember.fulfilled, (state, { payload }) => {
      teamMemberAdapter.updateOne(state.teamMembers, {
        id: payload.provider_team_member_id,
        changes: payload,
      });
      state.status.general = 'idle';
    });
    builder.addCase(editTeamMember.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(editTeamMemberOrder.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(editTeamMemberOrder.fulfilled, (state, { payload }) => {
      const allTeamMembers = teamMemberAdapter
        .getSelectors()
        .selectAll(state.teamMembers);

      const updatedTeamMembers = teamMemberOrderAdapter.updateTeamMembersOrder(
        allTeamMembers,
        payload,
      );

      teamMemberAdapter.setAll(state.teamMembers, updatedTeamMembers);

      state.status.general = 'idle';
    });
    builder.addCase(editTeamMemberOrder.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(removeTeamMember.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(removeTeamMember.fulfilled, (state, { payload }) => {
      teamMemberAdapter.removeOne(state.teamMembers, payload);
      state.status.general = 'idle';
    });
    builder.addCase(removeTeamMember.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });

    builder.addCase(createTags.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(createTags.fulfilled, (state, { payload }) => {
      state.status.general = 'idle';
    });
    builder.addCase(createTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(updateLogo.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updateLogo.fulfilled, (state, { payload }) => {
      state.providerProfile = { ...state.providerProfile, ...payload };
      state.company = { ...state.company, ...payload };
      state.status.general = 'idle';
    });
    builder.addCase(updateLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(deleteLogo.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(deleteLogo.fulfilled, (state, { payload }) => {
      state.providerProfile = { ...state.providerProfile, ...payload };
      state.status.general = 'idle';
    });
    builder.addCase(deleteLogo.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(updateBanner.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updateBanner.fulfilled, (state, { payload }) => {
      state.providerProfile = { ...state.providerProfile, ...payload };
      state.company = { ...state.company, ...payload };
      state.status.general = 'idle';
    });
    builder.addCase(updateBanner.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(deleteBanner.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(deleteBanner.fulfilled, (state, { payload }) => {
      state.providerProfile = { ...state.providerProfile, ...payload };
      state.status.general = 'idle';
    });
    builder.addCase(deleteBanner.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(updateVideos.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updateVideos.fulfilled, (state, { payload }) => {
      state.providerProfile.assets = [
        { ...state.providerProfile.assets, ...payload },
      ];
      state.status.general = 'idle';
    });
    builder.addCase(updateVideos.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(deleteVideos.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(deleteVideos.fulfilled, (state, { payload }) => {
      state.providerProfile = { ...state.providerProfile, ...payload };
      state.status.general = 'idle';
    });
    builder.addCase(deleteVideos.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(onboard.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(onboard.fulfilled, (state, { payload }) => {
      state.providerProfile.onboarded_at = payload;
      state.status.general = 'idle';
    });
    builder.addCase(onboard.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });

    builder.addCase(submitReferredRfp.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(submitReferredRfp.fulfilled, (state, { payload }) => {
      state.status.general = 'idle';
    });
    builder.addCase(submitReferredRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(fetchProfile.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(fetchProfile.fulfilled, (state, { payload }) => {
      state.providerProfile = payload;
      state.status.general = 'idle';
    });
    builder.addCase(fetchProfile.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(fetchCompanyUsers.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(fetchCompanyUsers.fulfilled, (state, { payload }) => {
      usersAdapter.setAll(state.users, payload);
      state.status.general = 'idle';
    });
    builder.addCase(fetchCompanyUsers.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(deleteCompanyUser.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(deleteCompanyUser.fulfilled, (state, { payload }) => {
      usersAdapter.removeOne(state.users, payload);
      state.status.general = 'idle';
    });
    builder.addCase(deleteCompanyUser.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });

    builder.addCase(fetchCompany.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.status.general = 'idle';
    });
    builder.addCase(fetchCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
      state.status.general = 'idle';
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.status.general = 'idle';
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
      state.status.general = 'idle';
    });
    builder.addCase(fetchContact.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(fetchContact.fulfilled, (state, { payload }) => {
      state.contact = payload;
      state.status.general = 'idle';
    });
    builder.addCase(fetchContact.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(updateContactById.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updateContactById.fulfilled, (state, { payload }) => {
      state.users = usersAdapter.setOne(state.users, payload);
      state.status.general = 'idle';
    });
    builder.addCase(updateContactById.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(updateContact.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      state.contact = payload;
      state.providerProfile.contacts = [payload];
      state.status.general = 'idle';
    });
    builder.addCase(updateContact.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
    builder.addCase(upsertCompanyUser.pending, (state) => {
      state.status.general = 'pending';
    });
    builder.addCase(upsertCompanyUser.fulfilled, (state, { payload }) => {
      usersAdapter.upsertOne(state.users, payload);
      state.status.general = 'idle';
    });
    builder.addCase(upsertCompanyUser.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status.general = 'idle';
    });
  },
});

export const selectPortfolios = createSelector(
  (state: RootState) => state.profile.portfolios,
  (portfolios) => {
    return portfolioAdapter.selectAll(portfolios);
  },
);

export const selectPortfolioById = createSelector(
  [
    (state: RootState, _) => state.profile.portfolios,
    (_, portfolioId: string) => portfolioId,
  ],
  (portfolios, portfolioId) => {
    return portfolioAdapter.getPortfolio(portfolios, portfolioId);
  },
);

export const {
  selectAll: selectTeamMembers,
  selectById: selectTeamMemberById,
} = teamMemberAdapter.getSelectors(
  (state: RootState) => state.profile.teamMembers,
);

export const selectProfile = (state: RootState) =>
  state.profile.providerProfile;

export const selectFormatedProfile = createSelector(
  (state: RootState) => state.profile.providerProfile,
  (provider) => convertProviderToProfile(provider),
);

export const selectProviderContact = createSelector(
  (state: RootState) => state.profile.providerProfile,
  (profile) => {
    return profile.contacts?.[0];
  },
);

export const profileReducer = profileSlice.reducer;

export const selectCompany = (state: RootState) => state.profile.company;
export const selectContact = (state: RootState) => state.profile.contact;

export const selectProfileState = (state: RootState) => state.profile;

export const selectHelloLoading = (state: RootState) =>
  state.profile.status.hello === 'pending';

export const selectProfileLoading = (
  state: RootState,
  type?: 'general' | 'assets' | 'hello',
) => state.profile.status[type ?? 'general'] === 'pending';

export const {
  selectAll: selectCompanyUsers,
  selectById: SelectCompanyUserById,
} = usersAdapter.getSelectors((state: RootState) => state.profile.users);
