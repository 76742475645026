import TrackingEvent from '@hellodarwin/core/lib/features/enums/tracking-event';

import { Provider } from '@hellodarwin/core/lib/features/entities';

type BaseAnalyticsProperties = {
  creation_source: string;
  id: string;
};

export default class PartnerAnalytics {
  private readonly analytics: Window['analytics'];
  private readonly partnerProfile: Provider;
  private readonly baseAnalyticsProperties: BaseAnalyticsProperties;

  constructor(analytics: Window['analytics'], partnerProfile: Provider) {
    this.analytics = analytics;
    this.partnerProfile = partnerProfile;
    this.baseAnalyticsProperties = {
      creation_source: 'Partner App',
      id: partnerProfile.contacts?.[0]?.contact_id || '',
    };
  }

  widgetSnippetCopied = () => {
    this.analytics?.track(TrackingEvent.WidgetSnippetCopied, {
      ...this.baseAnalyticsProperties,
      ...this.partnerProfile,
    });
  };

  referralLinkCLicked = () => {
    this.analytics?.track(TrackingEvent.ReferralLinkClicked, {
      ...this.baseAnalyticsProperties,
      ...this.partnerProfile,
    });
  };

  updatedPortfolio = (properties: { portfolioCount: number }) => {
    this.analytics?.track(TrackingEvent.UpdatedPortfolio, {
      ...this.baseAnalyticsProperties,
      ...this.partnerProfile,
      ...properties,
    });
  };

  updatedTeamMember = (properties: { teamMemberCount: number }) => {
    this.analytics?.track(TrackingEvent.UpdatedTeamMember, {
      ...this.baseAnalyticsProperties,
      ...this.partnerProfile,
      ...properties,
    });
  };

  updatedInformation = (properties: { logo: string }) => {
    this.analytics?.track(TrackingEvent.UpdatedInformation, {
      ...this.baseAnalyticsProperties,
      ...this.partnerProfile,
      ...properties,
    });
  };
}
