import {
  ApiError,
  ApiErrorInitialState,
  CategoryTagEntity,
  Industry,
  Tag,
  Tags,
} from '@hellodarwin/core/lib/features/entities';
import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import showErrorNotification from '../../helpers/show-error-notifications';

import { createTagAdapter } from '../adapters/tag-adapter';
import PartnerApiClient from '../partner-api-client';

export interface TagsState {
  status: 'idle' | 'pending';
  error: ApiError;
  industries: Industry[];
  tags: { [key: string]: CategoryTagEntity };
  categories: Tag[];
}
const tagAdapter = createTagAdapter();

const initialState: TagsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  industries: [],
  tags: tagAdapter.getInitialState(),
  categories: [],
};

export const fetchIndustries = createAsyncThunk<
  Industry[],
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/fetchIndustries',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchIndustries();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const toggleCategoryTag = createAction<string>(
  'partner/toggleCategoryTag',
);
export const toggleServiceTag = createAction<{ service: string }>(
  'partner/toggleServiceTag',
);
export const toggleSpecialtyTag = createAction<{ specialty: string }>(
  'partner/toggleSpecialtyTag',
);
export const setCategoryTag = createAction<{ category: string }>(
  'partner/setCategoryTag',
);
export const handleServiceChange = createAction<{
  service: string;
  minBudget: number;
  maxBudget: number;
  description?: string;
}>('partner/handleServiceBudgetChange');

export const fetchRfpTags = createAsyncThunk<
  Tags,
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/fetchTags',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.getTags();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchRfpCategoryTags = createAsyncThunk<
  Tag[],
  { api: PartnerApiClient },
  { rejectValue: ApiError }
>(
  'partner/fetchCategoryTags',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.getCategoryTags();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIndustries.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchIndustries.fulfilled, (state, { payload }) => {
      state.industries = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchIndustries.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpTags.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRfpTags.fulfilled, (state, { payload }) => {
      state.tags = tagAdapter.setAll(payload);
      state.status = 'idle';
    });
    builder.addCase(fetchRfpTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpCategoryTags.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRfpCategoryTags.fulfilled, (state, { payload }) => {
      state.categories = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchRfpCategoryTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(toggleCategoryTag, (state, { payload }) => {
      state.tags = tagAdapter.toggleCategoryTag(state.tags, payload);
    });
    builder.addCase(toggleServiceTag, (state, { payload }) => {
      state.tags = tagAdapter.toggleServiceTag(state.tags, payload.service);
    });
    builder.addCase(toggleSpecialtyTag, (state, { payload }) => {
      state.tags = tagAdapter.toggleSpecialtyTag(state.tags, payload.specialty);
    });
    builder.addCase(handleServiceChange, (state, { payload }) => {
      state.tags = tagAdapter.handleServiceChange(
        state.tags,
        payload.service,
        payload.minBudget,
        payload.maxBudget,
        payload.description,
      );
    });
  },
});

export const selectIndustriesSectors = createSelector(
  (state: RootState) => state.tags.industries,
  (industries) =>
    industries
      .filter((item) => item.level === 1)
      .map((item) => {
        return {
          label: `${item.id} - ${item.class_title}`,
          value: item.id,
        };
      }),
);

export const selectIndustriesSubsectors = createSelector(
  (state: RootState) => state.tags.industries,
  (industries) => industries.filter((item: Industry) => item.level === 2),
);

export const selectTagsIsLoading = (state: RootState) =>
  state.tags.status === 'pending';
export const selectCategoryTags = (state: RootState) => state.tags.categories;

export const selectTags = createSelector(
  (state: RootState) => state.tags.tags,
  (tags) => tagAdapter.selectTags(tags),
);

export const tagsReducer = tagsSlice.reducer;
