import { MappedTags } from '@hellodarwin/core/lib/features/entities/tags-entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Select, { DefaultOptionType } from 'antd/es/select';
import { useAppDispatch } from '../../../../app';
import { toggleServiceTag } from 'src/features/api/slices/tags-slice';

type ServiceSelectorProps = {
  selectedTags: MappedTags;
};

const ServiceSelector = ({ selectedTags }: ServiceSelectorProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const toggleService = (service: string) => {
    dispatch(toggleServiceTag({ service: service }));
  };

  const selectedTagsArray = selectedTags.selectedServices.map((tag) => tag.tag);

  const selectMenuOptions: DefaultOptionType[] =
    selectedTags.selectedCategories.map((category, index) => ({
      label: t(`tags|${category.label}`),
      options: category.services.map((service) => ({
        label: t(`tags|${service.label}`),
        key: service.tag,
        value: service.tag,
      })),
    }));

  return (
    <Select
      style={{ marginBottom: '1rem', width: '100%' }}
      size="large"
      allowClear
      mode="multiple"
      placeholder="Select the services you offer"
      options={selectMenuOptions}
      value={selectedTagsArray}
      onSelect={(tag: string) => toggleService(tag)}
      onDeselect={(tag: string) => toggleService(tag)}
      defaultValue={selectedTagsArray}
    />
  );
};

export default ServiceSelector;
