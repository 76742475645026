/* eslint-disable react-hooks/exhaustive-deps */
import { Tablet } from '@hellodarwin/core/lib/components/common/Media';
import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { Match } from '@hellodarwin/core/lib/features/entities';
import {
  dollarFormat,
  getShortId,
  getStringDate,
} from '@hellodarwin/core/lib/features/helpers';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Card from 'antd/es/card/Card';
import Divider from 'antd/es/divider';
import Tooltip from 'antd/es/tooltip';
import { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchContactById,
  selectContactById,
} from '../../../features/api/slices/global-slice';
import { usePartnerApi } from '../../../features/api/use-partner-api';
import RfpActions from './rfp-actions';
import RfpSpecialties from './rfp-specialties';
import RfpStatusTag from './rfp-status';

type RfpCardProps = {
  match: Match;
  price: number;
  discount: number;
};
const RfpCard = ({ match, price, discount }: RfpCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const { selectedLocale } = useLocale();

  const rfpContact = useAppSelector((state) =>
    selectContactById(state, match.rfp.contact_id || ''),
  );

  useEffect(() => {
    if (match?.rfp.contact_id) {
      dispatch(fetchContactById({ api, contactId: match.rfp.contact_id }));
    }
  }, [match]);

  const getProjetLink = () => {
    if (match?.rfp?.client?.contacts) {
      return `/projects/${match.match_id}/client`;
    } else {
      return `/projects/${match.match_id}`;
    }
  };

  if (!match.rfp) return <></>;

  const InfoRow = ({
    label,
    value,
  }: {
    label: string;
    value: string | ReactNode;
  }) => (
    <div style={{ flex: 1 }}>
      <Typography elementTheme="overline" color={theme.colors.grey_2} nowrap>
        {label}
      </Typography>
      <Typography elementTheme="body2" color={theme.colors.primary} nowrap>
        {value}
      </Typography>
    </div>
  );

  const isDirectorySource = match.source === 'Directory';

  return (
    <Link to={getProjetLink()}>
      <Card
        hoverable
        style={{
          borderRadius: 4,
          cursor: 'pointer',
          height: 'auto',
          width: '100%',
          overflow: 'hidden',
          padding: 0,
          boxShadow: theme.appearance.box_shadow,
          border: isDirectorySource
            ? `3px solid ${theme.colors.purple_2}`
            : `1px solid ${theme.colors.primary}`,
        }}
      >
        <Div
          flex="row"
          gap={32}
          justify="space-between"
          style={{ padding: '15px 24px' }}
          xl={{ flex: 'column' }}
        >
          <Div flex="column" gap={14}>
            <Div
              flex="row"
              gap={12}
              align="center"
              tablet={{ flex: 'column', align: 'flex-start' }}
            >
              <Div
                flex="row"
                gap={12}
                align="center"
                fitContent
                tablet={{ flex: 'column', align: 'flex-start' }}
              >
                <RfpStatusTag
                  status={match?.status?.toLowerCase() || ''}
                  statusMessage={
                    match.status === 'Rejected'
                      ? ` | ${t(`project$|${match.raise_hand_reject_reason}`)}`
                      : ''
                  }
                  tooltip={
                    match.status === 'Rejected'
                      ? ` | ${t(
                          `project$|${match.raise_hand_reject_reason_specified}`,
                        )}`
                      : ''
                  }
                />
                {isDirectorySource && (
                  <Tooltip
                    title={
                      <>
                        {parse(
                          t(`rfp|rfpCard.directoryMatchMoreInfo`, {
                            PageURL: match.rfp.source_url,
                            PageName: match.rfp.ad_group_id,
                          }),
                        )}
                      </>
                    }
                  >
                    {''}
                    <HdTag
                      text={t(`rfp|rfpCard.directoryMatch`)}
                      color={theme.colors.green_2}
                    />
                  </Tooltip>
                )}
              </Div>
              <Tablet direction="up">
                <Divider
                  type="vertical"
                  style={{
                    borderColor: theme.colors.grey_4,
                    borderWidth: 1,
                    margin: 0,
                    height: 14,
                  }}
                />
              </Tablet>

              <Typography
                elementTheme="caption"
                color={theme.colors.grey_2}
                nowrap
              >
                {getStringDate(
                  new Date(`${match?.updated_at || match?.created_at}`),
                  selectedLocale,
                )}
              </Typography>
            </Div>
            <Div flex="column" gap={6}>
              <Typography.Title level={3} elementTheme="subtitle2" noMargin>
                {match?.rfp?.title ? match?.rfp?.title : match?.rfp?.service}
              </Typography.Title>
              <Div flex="row" gap={12} align="center">
                <Typography
                  elementTheme="caption"
                  color={theme.colors.grey_2}
                  nowrap
                >
                  ID #{getShortId(match?.rfp_id)}
                </Typography>
                <Divider
                  type="vertical"
                  style={{
                    borderColor: theme.colors.grey_4,
                    borderWidth: 1,
                    margin: 0,
                    height: 14,
                  }}
                />
                <Typography
                  elementTheme="caption"
                  color={theme.colors.grey_2}
                  nowrap
                >
                  {match?.rfp?.service}
                </Typography>
              </Div>
            </Div>
          </Div>

          {!!match.status && <RfpActions match={match} />}
        </Div>
        <Divider
          style={{
            borderColor: theme.colors.primary,
            borderWidth: 1,
            margin: 0,
          }}
        />

        <Div flex="row" xl={{ flex: 'column' }}>
          <Div
            style={{ padding: '19px 24px', flex: 1 }}
            borderColor={theme.colors.primary}
            bordersSide="right"
            xl={{ bordersSide: 'bottom' }}
            flex="column"
            gap={11}
          >
            <Typography elementTheme="overline" medium>
              {t('rfp|rfpCard.aboutRfp')}
            </Typography>
            <Div flex="column" gap={18}>
              <Div flex="row" gap={75} xl={{ flex: 'column', gap: 18 }}>
                {!!match?.rfp?.start_when && (
                  <InfoRow
                    label={t(`rfp|rfpPage.startWhen`)}
                    value={t(
                      `rfp|rfpStartWhen.${match?.rfp?.start_when?.toLowerCase()}`,
                    )}
                  />
                )}
                {!!match?.rfp?.raise_hand_price && (
                  <InfoRow
                    label={t(`rfp|rfpPage.raiseHandPrice`)}
                    value={
                      isDirectorySource ? (
                        <Div fitContent flex="row" gap={8}>
                          <Typography
                            strike
                            elementTheme="body2"
                            color={theme.colors.red_1}
                          >
                            {`${dollarFormat(
                              match?.rfp?.raise_hand_price || 0,
                              selectedLocale,
                            )}$`}
                          </Typography>
                          <Typography elementTheme="body2">{`${dollarFormat(
                            isDirectorySource
                              ? 0
                              : match?.rfp?.raise_hand_price,
                            selectedLocale,
                          )}$`}</Typography>
                        </Div>
                      ) : (
                        `${dollarFormat(
                          match?.rfp?.raise_hand_price || 0,
                          selectedLocale,
                        )}$`
                      )
                    }
                  />
                )}
              </Div>
              <Div flex="row" gap={75} xl={{ flex: 'column', gap: 18 }}>
                {!!match?.rfp?.estimated_value && (
                  <InfoRow
                    label={t(`rfp|rfpPage.budget`)}
                    value={`${dollarFormat(
                      match?.rfp?.estimated_value || 0,
                      selectedLocale,
                    )}$`}
                  />
                )}
                {!!price && (
                  <InfoRow
                    label={t(`rfp|rfpPage.leadCost`)}
                    value={
                      discount > 0 || isDirectorySource ? (
                        <Div fitContent flex="row" gap={8}>
                          <Typography
                            strike
                            elementTheme="body2"
                            color={theme.colors.red_1}
                          >
                            {`${dollarFormat(price || 0, selectedLocale)}$`}
                          </Typography>
                          <Typography elementTheme="body2">{`${dollarFormat(
                            isDirectorySource ? 0 : price - discount,
                            selectedLocale,
                          )}$`}</Typography>
                        </Div>
                      ) : (
                        `${dollarFormat(price || 0, selectedLocale)}$`
                      )
                    }
                  />
                )}
              </Div>
            </Div>
          </Div>
          <Div
            style={{ padding: '19px 28px', flex: 1 }}
            borderColor={theme.colors.primary}
            bordersSide="right"
            xl={{ bordersSide: 'bottom' }}
            flex="column"
            gap={11}
          >
            <Typography elementTheme="overline" medium>
              {t('rfp|rfpCard.aboutClient')}
            </Typography>
            <Div gap={18} flex="column">
              {match.status === 'Shortlisted' ? (
                <>
                  <Div flex="row" gap={75} xl={{ flex: 'column', gap: 18 }}>
                    {!!rfpContact?.name && (
                      <InfoRow
                        label={t(`rfp|clientDetails.labels.contact`)}
                        value={rfpContact?.name}
                      />
                    )}
                    {!!rfpContact?.name && (
                      <InfoRow
                        label={t(`rfp|clientDetails.labels.company`)}
                        value={match.rfp.client?.name}
                      />
                    )}
                  </Div>{' '}
                  <Div flex="row" gap={75} xl={{ flex: 'column', gap: 18 }}>
                    {!!rfpContact?.email && (
                      <InfoRow
                        label={t(`rfp|clientDetails.labels.email`)}
                        value={rfpContact?.email}
                      />
                    )}
                    {!!rfpContact?.phone && (
                      <InfoRow
                        label={t(`rfp|clientDetails.labels.phone`)}
                        value={rfpContact?.phone}
                      />
                    )}
                  </Div>
                </>
              ) : (
                <>
                  {!!match.rfp.industry && (
                    <InfoRow
                      label={t(`rfp|clientDetails.labels.industry`)}
                      value={match.rfp.industry}
                    />
                  )}

                  <Div flex="row" gap={75} xl={{ flex: 'column', gap: 18 }}>
                    {!!match.rfp.client?.size && (
                      <InfoRow
                        label={t(`rfp|clientDetails.labels.size`)}
                        value={match?.rfp?.client?.size}
                      />
                    )}
                    {!!match.rfp.client?.city && (
                      <InfoRow
                        label={t(`rfp|clientDetails.labels.city`)}
                        value={match?.rfp?.client?.city}
                      />
                    )}
                  </Div>
                </>
              )}
            </Div>
          </Div>

          <Div
            style={{ padding: '19px 28px', flex: 1 }}
            xl={{ bordersSide: 'bottom', fitMinContent: true }}
            flex="column"
            gap={18}
          >
            {!!match.rfp.specialties && (
              <Div flex="column" gap={11}>
                <Typography elementTheme="overline" medium>
                  {t('rfp|rfpCard.tags')}
                </Typography>
                <RfpSpecialties specialties={match.rfp.specialties} />
              </Div>
            )}
            <Div flex="row" gap={11}>
              {match.rfp?.raise_hand_count && (
                <Div flex="column" gap={11}>
                  <Typography elementTheme="overline" medium nowrap>
                    {t('rfp|rfpCard.candidates')}
                  </Typography>
                  {match.rfp?.raise_hand_count}
                </Div>
              )}
              {match.rfp?.shortlist_count && (
                <Div flex="column" gap={11}>
                  <Typography elementTheme="overline" medium nowrap>
                    {t('rfp|rfpCard.shortlist')}
                  </Typography>
                  {match.rfp?.shortlist_count}/{match?.rfp?.provider_max}
                </Div>
              )}
            </Div>
          </Div>
        </Div>
      </Card>
    </Link>
  );
};

export default RfpCard;
