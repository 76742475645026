import Div from '@hellodarwin/core/lib/components/common/div';
import { ModalConfirm } from '@hellodarwin/core/lib/components/common/hd-modal/confirm';
import RfpActionsButton from '@hellodarwin/core/lib/components/rfp/rfp-actions-button';
import { Match } from '@hellodarwin/core/lib/features/entities';
import {
  Action,
  PrioritizedRfpActions,
} from '@hellodarwin/core/lib/features/enums/action-entities';
import RfpType from '@hellodarwin/core/lib/features/enums/rfp-type';
import getShortId from '@hellodarwin/core/lib/features/helpers/get-short-id';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import { toggleModal } from '../../../features/api/slices/global-slice';
import {
  archiveRfp,
  declareSelfWinner,
  setSelectedMatchId,
  unarchiveRfp,
  unignoreRfp,
} from '../../../features/api/slices/matches-slice';
import { selectProfile } from '../../../features/api/slices/profile-slice';
import { usePartnerApi } from '../../../features/api/use-partner-api';
import showErrorNotification from '../../../features/helpers/show-error-notifications';

type RfpActionsProps = {
  match: Match;
};

const RfpActions = ({ match }: RfpActionsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const navigate = useNavigate();
  const profile = useAppSelector(selectProfile);
  const rfpType = match.rfp.type;
  const matchId = match.match_id;
  const raiseHand = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedMatchId(matchId));
    dispatch(toggleModal({ isVisible: true, type: 'raiseHandPurchase' }));
  };

  const purchaseLead = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(setSelectedMatchId(matchId));
    dispatch(toggleModal({ isVisible: true, type: 'leadPurchase' }));
  };

  const ignoreRfp = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedMatchId(matchId));
    dispatch(toggleModal({ isVisible: true, type: 'ignoreProject' }));
  };

  const archiveMatch = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await dispatch(archiveRfp({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    } finally {
      navigate('/projects/ongoing');
    }
  };

  const unarchiveMatch = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await dispatch(unarchiveRfp({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    } finally {
      navigate('/projects/ongoing');
    }
  };

  const unIgnoreMatch = async (e: SyntheticEvent) => {
    e.stopPropagation();
    try {
      await dispatch(unignoreRfp({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const fetchInvoice = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const invoice = await api.fetchInvoice(matchId);
      window.open(invoice.HostedURL, '_blank');
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const fetchRhInvoice = async (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const invoice = await api.fetchRaisehandInvoice(matchId);
      window.open(invoice.HostedURL, '_blank');
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const fetchWinFeeInvoice = async (e?: SyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    try {
      const invoice = await api.fetchWinFeeInvoice(matchId);
      window.open(invoice.HostedURL, '_blank');
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    }
  };

  const handleCreditRequest = () => {
    window.location.href = t('rfp|creditEmailTemplate', {
      rfp_id: getShortId(match.rfp_id),
      company_name: profile.name,
    });
  };

  const declareWinner = async (e?: SyntheticEvent) => {
    ModalConfirm({
      title: t('rfp|rfpActions|declareWinnerConfirmationTitle'),
      content: t('rfp|rfpActions|declareWinnerConfirmationContent'),
      okText: t('button.confirm'),
      cancelText: t('button.cancel'),
      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: '24px',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: '24px',
        },
      },
      onOk: async () => {
        declareWinnerConfirmed(e);
      },
      width: 600,
    });
  };

  const declareWinnerConfirmed = async (e?: SyntheticEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    try {
      await dispatch(declareSelfWinner({ api, matchId }));
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
    } finally {
      navigate('/projects/closed');
    }
  };

  const [actions, setActions] = useState<ReactNode>(<></>);

  const viewInvoiceAction: Action = {
    label: t('rfp|rfpActions|viewInvoice'),
    action: fetchInvoice,
  };

  const viewRhInvoiceAction: Action = {
    label: t('rfp|rfpActions|viewRhInvoice'),
    action: fetchRhInvoice,
  };

  const viewWinFeeInvoiceAction: Action = {
    label: t('rfp|rfpActions|viewWinFeeInvoice'),
    action: fetchWinFeeInvoice,
  };

  const creditRequestAction: Action = {
    label: t('rfp|rfpActions|creditRequest'),
    action: handleCreditRequest,
  };

  const archiveAction: Action = {
    label: t('rfp|rfpActions|archive'),
    action: archiveMatch,
  };

  const unarchiveAction: Action = {
    label: t('rfp|rfpActions|unarchive'),
    action: unarchiveMatch,
  };

  const raiseHandAction: Action = {
    label: t('rfp|rfpActions|raiseHand'),
    action: raiseHand,
  };

  const declareWinnerAction: Action = {
    label: t('rfp|rfpActions|declareWinner'),
    action: declareWinner,
  };

  const ignoredProjectActions: PrioritizedRfpActions = {
    primaryAction: [
      {
        label: t('rfp|rfpActions|removeFromIgnore'),
        action: unIgnoreMatch,
      },
    ],
    secondaryActions: [],
  };

  const newClassicProjectActions: PrioritizedRfpActions = {
    primaryAction: [
      {
        label: t('rfp|rfpActions|buy'),
        action: purchaseLead,
      },
    ],
    secondaryActions: [
      { label: t('rfp|rfpActions|ignore'), action: ignoreRfp },
    ],
  };
  const newRhProjectActions: PrioritizedRfpActions = {
    primaryAction: [raiseHandAction],
    secondaryActions: [
      { label: t('rfp|rfpActions|ignore'), action: ignoreRfp },
    ],
  };
  const rejectedProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [archiveAction],
  };
  const rejectedRhProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewRhInvoiceAction],
    secondaryActions: [archiveAction],
  };
  const archivedProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [creditRequestAction, unarchiveAction],
  };
  const archivedRhProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewRhInvoiceAction],
    secondaryActions: [creditRequestAction, unarchiveAction],
  };
  const shortlistProjectActions: PrioritizedRfpActions = {
    primaryAction: [declareWinnerAction],
    secondaryActions: [viewInvoiceAction, viewRhInvoiceAction, archiveAction],
  };
  const shortlistForeverProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [viewRhInvoiceAction, archiveAction],
  };
  const winnerProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [archiveAction],
  };
  const rhWinnerProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [viewRhInvoiceAction, archiveAction],
  };

  if (match.win_fee_invoice_id !== '') {
    rhWinnerProjectActions.secondaryActions?.push(viewWinFeeInvoiceAction);
  }

  const defaultProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewInvoiceAction],
    secondaryActions: [creditRequestAction, archiveAction],
  };
  const defaultRhProjectActions: PrioritizedRfpActions = {
    primaryAction: [viewRhInvoiceAction],
    secondaryActions: [creditRequestAction, archiveAction],
  };

  useEffect(() => {
    switch (match.status) {
      case 'Ignored':
        setActions(<RfpActionsButton actions={ignoredProjectActions} />);
        break;
      case 'New':
        if (rfpType === RfpType.Classic) {
          setActions(<RfpActionsButton actions={newClassicProjectActions} />);
          break;
        }
        setActions(<RfpActionsButton actions={newRhProjectActions} />);
        break;
      case 'Waiting':
      case 'Rejected':
        if (rfpType === RfpType.Classic) {
          setActions(<RfpActionsButton actions={rejectedProjectActions} />);
          break;
        }
        setActions(<RfpActionsButton actions={rejectedRhProjectActions} />);
        break;
      case 'Archive':
        if (rfpType === RfpType.Classic) {
          setActions(<RfpActionsButton actions={archivedProjectActions} />);
          break;
        }
        setActions(<RfpActionsButton actions={archivedRhProjectActions} />);
        break;
      case 'Bought':
      case 'Winner':
        if (rfpType === RfpType.Classic) {
          setActions(<RfpActionsButton actions={winnerProjectActions} />);
          break;
        }
        setActions(<RfpActionsButton actions={rhWinnerProjectActions} />);
        break;
      case 'Shortlisted':
        setActions(<RfpActionsButton actions={shortlistProjectActions} />);
        break;
      case 'Finalist':
        setActions(
          <RfpActionsButton actions={shortlistForeverProjectActions} />,
        );
        break;
      default:
        if (rfpType === RfpType.Classic) {
          setActions(<RfpActionsButton actions={defaultProjectActions} />);
          break;
        }
        setActions(<RfpActionsButton actions={defaultRhProjectActions} />);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.status, t]);

  return (
    <Div flex={'row'} justify={'flex-end'} gap={8} align="center">
      <Div flex="row" gap={8} fitContent align="center">
        {actions}
      </Div>
    </Div>
  );
};

export default RfpActions;
