import Divider from 'antd/es/divider';
import React from 'react';
import { Rfp } from '../../../../features/entities/rfp-entities';
import RfpDescriptionType from '../../../../features/enums/rfp-description-type';
import parse, {
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from '../../../../features/helpers/parse';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import RfpSingleTile from './rfp-single-tile';

type RfpDescriptionProps = {
  rfp: Rfp;
  isPartner?: boolean;
  rfpManager?: string;
};

const RfpDescription = ({
  rfp,
  isPartner,
  rfpManager,
}: RfpDescriptionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (domNode.attribs) {
        const props = attributesToProps(domNode.attribs);
        if (domNode.name === 'ul') {
          return (
            <Typography elementTheme="body2">
              <ul {...props}>{domToReact(domNode.children)}</ul>
            </Typography>
          );
        }
        if (domNode.name === 'h4') {
          return (
            <Div flex="column" gap={6}>
              <Typography
                elementTheme="caption"
                color={theme.colors.grey_3}
                textTransform="uppercase"
                style={{ letterSpacing: 0.3 }}
                bold
              >
                {domToReact(domNode.children)}
              </Typography>
              <Divider style={{ margin: 0 }} />
            </Div>
          );
        }
      }
    },
  };

  return (
    <RfpSingleTile>
      <Typography elementTheme="body2" color={theme.colors.primary}>
        {isPartner && (
          <>
            {rfp.special_conditions &&
              rfp.special_conditions !== '<p><br></p>' && (
                <Div flex="column" gap={12}>
                  <Div flex="column" gap={6}>
                    <Typography
                      elementTheme="caption"
                      color={theme.colors.grey_3}
                      textTransform="uppercase"
                      style={{ letterSpacing: 0.3 }}
                      bold
                    >
                      {t(`rfp|rfpPage.specialConditions`)}
                    </Typography>
                    <Divider style={{ margin: 0 }} />
                  </Div>
                  <Typography elementTheme="body2">
                    {parse(rfp.special_conditions as string)}
                  </Typography>
                </Div>
              )}
            {rfp.additional_comments &&
              parse(rfp.additional_comments) !== '' && (
                <Div flex="column" gap={12}>
                  <Div flex="column" gap={6}>
                    <Typography
                      elementTheme="caption"
                      color={theme.colors.grey_3}
                      textTransform="uppercase"
                      style={{ letterSpacing: 0.3 }}
                      bold
                    >
                      {t(`rfp|rfpPage.additionalComments`)}
                    </Typography>
                    <Divider style={{ margin: 0 }} />
                  </Div>
                  <Typography elementTheme="body2">
                    {parse(rfp.additional_comments as string)}
                  </Typography>
                </Div>
              )}
          </>
        )}

        {!isPartner && rfp.status === 'PendingApproval' ? (
          <Div flex="column" gap={12}>
            {rfp.original_description
              ? parse(rfp.original_description, options)
              : ''}
          </Div>
        ) : rfp.description ? (
          <Div> {parse(rfp.description, options)} </Div>
        ) : (
          rfp.description_container && (
            <Div flex="column" gap={12}>
              {React.Children.toArray(
                Object.values(RfpDescriptionType).map((descriptionType) => {
                  if (rfp.description_container?.[descriptionType]) {
                    return (
                      <React.Fragment key={descriptionType}>
                        <Div
                          bordersSide="bottom"
                          borderColor={theme.colors.grey_5}
                        >
                          <Typography
                            className="hd-mb-05"
                            style={{ letterSpacing: 0.3 }}
                            elementTheme="caption"
                            bold
                            color={theme.colors.grey_3}
                          >
                            {t(
                              `rfp|rfpPage.rfpDescriptionType.${descriptionType}`,
                            )}
                          </Typography>
                        </Div>
                        <Div>
                          {parse(
                            rfp.description_container?.[descriptionType],
                            options,
                          )}
                        </Div>
                      </React.Fragment>
                    );
                  }
                  return null;
                }),
              )}
            </Div>
          )
        )}
      </Typography>
    </RfpSingleTile>
  );
};

export default RfpDescription;
