import {
  ApiError,
  ApiErrorInitialState,
  Rfp,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app';
import PartnerApiClient from '../partner-api-client';

const rfpAdapter = createEntityAdapter<Rfp, string>({
  selectId: (rfp: Rfp) => rfp.rfp_id,
});

export interface clientState {
  status: 'idle' | 'pending';
  error: ApiError;
  rfps: EntityState<Rfp, string>;
}

const initialState: clientState = {
  status: 'idle',
  error: ApiErrorInitialState,
  rfps: rfpAdapter.getInitialState(),
};

export const fetchWonRfps = createAsyncThunk<
  Rfp[],
  { api: PartnerApiClient },
  { rejectValue: ApiError; state: RootState }
>(
  'partner/fetchWonRfps',
  async ({ api }: { api: PartnerApiClient }, { rejectWithValue }) => {
    try {
      return await api.fetchWonRfp();
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

const rfpSlice = createSlice({
  name: 'rfps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWonRfps.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        rfpAdapter.addMany(state.rfps, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(fetchWonRfps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchWonRfps.pending, (state) => {
      state.status = 'pending';
    });
  },
});

export const { selectAll: selectAllRfp, selectById: selectRfp } =
  rfpAdapter.getSelectors((state: RootState) => state.rfp.rfps);

export const selectRfpsLoading = (state: RootState) =>
  state.rfp.status === 'pending';

export const rfpReducer = rfpSlice.reducer;
