import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import { Match } from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Tooltip from 'antd/es/tooltip';

import getStatusColor from '../../../features/helpers/get-rfp-status-color';

type RfpStatusProps = {
  status: Match['status'];
  statusMessage?: string;
  tooltip: string;
};

const RfpStatus = ({ status, statusMessage, tooltip }: RfpStatusProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={tooltip}>
      <HdTag
        text={t(`rfp|rfpStatus|${status}`) + statusMessage}
        color={getStatusColor(status)}
        withBorder
      />
    </Tooltip>
  );
};

export default RfpStatus;
