import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import Loading from '@hellodarwin/core/lib/components/loading';
import { Rfp } from '@hellodarwin/core/lib/features/entities';
import {
  getFormattedDate,
  getShortId,
} from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { useTheme } from '@hellodarwin/core/lib/plugins/styled';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import { ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app';
import {
  fetchContactById,
  selectContactById,
  selectLoading,
} from '../../../features/api/slices/global-slice';
import { usePartnerApi } from '../../../features/api/use-partner-api';

interface ReferralRfpCardProps {
  rfp: Rfp;
}
const ReferralRfpCard = ({ rfp }: ReferralRfpCardProps) => {
  const { selectedLocale } = useLocale();
  const { t } = useTranslation();
  const theme = useTheme();

  const api = usePartnerApi();
  const dispatch = useAppDispatch();

  const contactLoading = useAppSelector(selectLoading);
  const rfpContact = useAppSelector((state) =>
    selectContactById(state, rfp.contact_id || ''),
  );

  useEffect(() => {
    if (rfp.contact_id) {
      dispatch(fetchContactById({ api, contactId: rfp.contact_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const InfoRow = ({
    label,
    value,
  }: {
    label: string;
    value: string | ReactNode;
  }) => (
    <div style={{ flex: 1 }}>
      <Typography elementTheme="overline" color={theme.colors.grey_2} nowrap>
        {label}
      </Typography>
      <Typography elementTheme="body2" color={theme.colors.primary} nowrap>
        {value}
      </Typography>
    </div>
  );

  if (contactLoading) return <Loading />;

  return (
    <Card
      style={{
        borderRadius: 4,

        height: 'auto',
        width: '100%',
        overflow: 'hidden',
        padding: 0,
        boxShadow: theme.appearance.box_shadow,
        border: `1px solid ${theme.colors.primary}`,
      }}
    >
      <Div
        flex="row"
        gap={32}
        justify="space-between"
        style={{ padding: '15px 24px' }}
        xl={{ flex: 'column' }}
      >
        <Div flex="column" gap={14}>
          <Div
            flex="row"
            gap={12}
            align="center"
            fitContent
            tablet={{ flex: 'column', align: 'flex-start' }}
          >
            {rfp?.status === 'Active' ? (
              <HdTag
                text={
                  <>
                    {t('referral|myReferral.pendingCreditTag')} &nbsp;
                    {rfp?.lead_price}$
                  </>
                }
                color={theme.colors.yellow_1}
              />
            ) : (
              rfp?.status === 'Completed' && (
                <HdTag
                  text={rfp?.lead_price + '$'}
                  color={theme.colors.green_1}
                />
              )
            )}
            {rfp.status !== 'PendingApproval' && (
              <Divider
                type="vertical"
                style={{
                  borderColor: theme.colors.grey_4,
                  borderWidth: 1,
                  margin: 0,
                  height: 14,
                }}
              />
            )}
            <HdTag
              text={
                rfp?.created_at ? (
                  <>
                    {t('referral|myReferral.referredOn')} &nbsp;
                    {getFormattedDate(rfp?.created_at || '', selectedLocale)}
                  </>
                ) : (
                  rfp?.activated_at && (
                    <>
                      {t('referral|myReferral.activatedOn')} &nbsp;
                      {getFormattedDate(
                        rfp?.activated_at || '',
                        selectedLocale,
                      )}
                    </>
                  )
                )
              }
              color={theme.colors.grey_2}
            />
          </Div>

          <Typography.Title level={3} elementTheme="subtitle2" noMargin>
            {rfpContact?.name}
          </Typography.Title>
        </Div>
      </Div>
      <Divider
        style={{
          borderColor: theme.colors.primary,
          borderWidth: 1,
          margin: 0,
        }}
      />
      <Div flex="row" xl={{ flex: 'column' }}>
        <Div
          style={{ padding: '19px 24px', flex: 1 }}
          borderColor={theme.colors.primary}
          bordersSide="right"
          xl={{ bordersSide: 'bottom' }}
          flex="column"
          gap={11}
        >
          <Typography elementTheme="overline" medium>
            {t('rfp|rfpCard.aboutRfp')}
          </Typography>
          <Div flex="column" gap={18}>
            {rfp?.status === 'Active' ? (
              <>
                <InfoRow
                  label={t('referral|myReferral.creditsPending')}
                  value={`${rfp?.referral_credit_amount}$`}
                />
                <InfoRow
                  label={t(`rfp|rfpPage|leadCost`)}
                  value={`${rfp?.lead_price}$`}
                />
              </>
            ) : (
              rfp?.status === 'Completed' && (
                <>
                  <InfoRow
                    label={t('referral|myReferral.receivedCredits')}
                    value={`${rfp?.referral_credit_amount}$`}
                  />
                </>
              )
            )}
            <InfoRow label={t(`rfp|rfpId`)} value={getShortId(rfp.rfp_id)} />
            <InfoRow
              label={t('referral|myReferral.projectStatus')}
              value={t(`referral|myReferral.status.${rfp.status}`)}
            />
          </Div>
        </Div>
        <Div style={{ padding: '19px 24px', flex: 1 }} flex="column" gap={11}>
          <Typography elementTheme="overline" medium>
            {t('rfp|rfpCard.aboutClient')}
          </Typography>
          <InfoRow
            label={t('referral|myReferral.projectTitle')}
            value={rfp.title ?? t('referral|myReferral.titleTBA')}
          />
          <InfoRow
            label={t('referral|myReferral.clientEmail')}
            value={rfpContact?.email}
          />
          {rfp?.referral_credit_applied_at && (
            <InfoRow
              label={t('referral|myReferral.referredOn')}
              value={getFormattedDate(
                rfp?.referral_credit_applied_at || '',
                selectedLocale,
              )}
            />
          )}
        </Div>
      </Div>
    </Card>
  );
};

export default ReferralRfpCard;
