import Div from '@hellodarwin/core/lib/components/common/div';
import Grid from '@hellodarwin/core/lib/components/common/hd-grid';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  Match,
  RfpRowInformation,
} from '@hellodarwin/core/lib/features/entities';
import { getShortFormattedDate } from '@hellodarwin/core/lib/features/helpers';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import Tooltip from 'antd/es/tooltip';
import RfpActions from './rfp-actions';
import RfpInformationRow from './rfp-information-row';
import RfpRaiseHandRHTag from './rfp-raisehand-rh';
import RfpSpecialties from './rfp-specialties';
import RfpStatus from './rfp-status';

type RfpBannerProps = {
  match: Match;
  showTags?: boolean;
  isCard?: boolean;
  available?: boolean;
  informationsLeft?: RfpRowInformation[];
  informationsRight?: RfpRowInformation[];
};

const RfpBanner = ({
  match,
  showTags,
  isCard,
  available,
  informationsLeft,
  informationsRight,
}: RfpBannerProps) => {
  const { t } = useTranslation();

  const RfpTitle = () => {
    return (
      <Div
        flex={'column'}
        bordersSide="bottom"
        borderColor={isCard ? theme.colors.purple_5 : undefined}
        style={{ padding: isCard ? '1.5rem' : undefined }}
      >
        <Div
          flex="row"
          align="center"
          justify="space-between"
          tablet={{ flex: 'column-reverse', align: 'flex-start' }}
          gap={16}
        >
          <Div flex="column" gap={16}>
            {match.status && (
              <Div flex={'row'} tablet={{ flex: 'column' }} gap={8} fitContent>
                <RfpStatus
                  status={match.status?.toLowerCase() || ''}
                  statusMessage={
                    match.status === 'Rejected'
                      ? ` | ${t(`project$|${match.raise_hand_reject_reason}`)}`
                      : ''
                  }
                  tooltip={
                    match.status === 'Rejected'
                      ? ` | ${t(
                          `project$|${match.raise_hand_reject_reason_specified}`,
                        )}`
                      : ''
                  }
                />
                {match.source === 'Directory' && (
                  <Tooltip
                    title={
                      <>
                        {parse(
                          t(`rfp|rfpCard.directoryMatchMoreInfo`, {
                            PageURL: match.rfp.source_url,
                            PageName: match.rfp.ad_group_id,
                          }),
                        )}
                      </>
                    }
                  >
                    {''}
                    <HdTag
                      text={t(`rfp|rfpCard.directoryMatch`)}
                      color={theme.colors.green_2}
                    />
                  </Tooltip>
                )}
                {match.rfp.updated_at && (
                  <HdTag
                    color={theme.colors.yellow_2}
                    text={getShortFormattedDate(match.rfp.updated_at)}
                  />
                )}

                {match.rfp.rh_status_details && (
                  <RfpRaiseHandRHTag message={match.rfp.rh_status_details} />
                )}
              </Div>
            )}
            <Div>
              <Div
                className={'project-alias-container'}
                flex={'row'}
                align={'center'}
                gap={8}
              >
                <Typography elementTheme="body1" className="project-title">
                  {match.rfp.title ? match.rfp.title : match.rfp.service}
                </Typography>
              </Div>
              <Typography elementTheme="caption" color={theme.colors.grey_3}>
                {t(`rfp|rfpType|${match.rfp.type}`)} - {match.rfp.category}
              </Typography>
            </Div>
          </Div>
          {!!available && (
            <Div
              flex="row"
              style={{ width: 'fit-content' }}
              tablet={{ style: { width: '100%' } }}
            >
              <RfpActions match={match} />
            </Div>
          )}
        </Div>
      </Div>
    );
  };

  const RfpInformations = () => {
    return (
      <Grid xl={2} sm={1}>
        {!!informationsLeft?.length && (
          <Div flex="column">
            {informationsLeft?.map((info) => (
              <RfpInformationRow info={info} key={info.title} />
            ))}
          </Div>
        )}
        {!!informationsRight?.length && (
          <Div
            flex="column"
            align={'flex-end'}
            tablet={{ align: 'flex-start' }}
          >
            {informationsRight?.map((info) => (
              <RfpInformationRow
                key={info.title}
                info={info}
                justify="flex-end"
                tablet={{ justify: 'flex-start' }}
              />
            ))}
          </Div>
        )}
      </Grid>
    );
  };

  const RfpTags = () => {
    return (
      <>
        {showTags && !!match.rfp?.specialties && (
          <Grid
            className={'project-banner-footer'}
            xl={2}
            sm={1}
            style={{ margin: 0 }}
          >
            <RfpSpecialties specialties={match.rfp.specialties || []} />
          </Grid>
        )}
      </>
    );
  };

  return (
    <Div flex="column" gap={isCard ? 0 : 16}>
      <RfpTitle />
      <Div
        style={{ padding: isCard ? '1.5rem' : undefined }}
        flex="column"
        gap={16}
      >
        <RfpInformations />
        <RfpTags />
      </Div>
    </Div>
  );
};
export default RfpBanner;
