/* eslint-disable react-hooks/exhaustive-deps */
import FileOutlined from '@ant-design/icons/FileOutlined';
import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import RfpDescription from '@hellodarwin/core/lib/components/rfp/single/rfp-content/rfp-description';
import { Match } from '@hellodarwin/core/lib/features/entities';
import RfpStatus from '@hellodarwin/core/lib/features/enums/rfp-status';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import { TabsProps } from 'antd/es/tabs';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import {
  fetchContactById,
  selectActiveTab,
  selectContactById,
  setActiveTab,
} from '../../features/api/slices/global-slice';
import { usePartnerApi } from '../../features/api/use-partner-api';
import ClientDetails from './client-details';
import RfpHeader from './rfp-banner/rfp-header';
import RfpDetails from './rfp-details';
import RfpShortlistMessage from './rfp-shortlist-message';

interface SingleRfpProps {
  match: Match;
  defaultTab: 'project' | 'client' | 'attachment';
}

const SingleRfp = ({ match, defaultTab }: SingleRfpProps) => {
  const { t } = useTranslation();

  const activeTab = useAppSelector(selectActiveTab);
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const [available, setAvailable] = useState(true);
  const rfpContact = useAppSelector((state) =>
    selectContactById(state, match?.rfp.contact_id || ''),
  );

  const navigate = useNavigate();

  const handleTabClick = (tab: string) => dispatch(setActiveTab(tab));
  useEffect(() => {
    handleTabClick(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 'project':
        navigate(`/projects/${match.match_id}`, { replace: true });
        break;
      case 'client':
        navigate(`/projects/${match.match_id}/client`, { replace: true });
        break;
      case 'attachment':
        navigate(`/projects/${match.match_id}`, { replace: true });
        break;
      default:
        navigate(`/projects/${match.match_id}`, { replace: true });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (
      !match.purchased_at &&
      !match.raised_hand_at &&
      match.rfp.status !== RfpStatus.Active
    ) {
      setAvailable(false);
    } else {
      setAvailable(true);
    }
    if (match?.rfp?.contact_id && match.purchased_at) {
      dispatch(fetchContactById({ api, contactId: match?.rfp?.contact_id }));
    }
  }, [match]);

  useEffect(() => {
    if (!match?.rfp?.assets?.length && activeTab === 'attachment') {
      setActiveTab('project');
    }
    if (!match.rfp.client?.contacts?.length && activeTab === 'client') {
      setActiveTab('project');
    }
  }, [activeTab, match]);

  const getProjectContent = (tab: string) => {
    switch (tab) {
      case 'attachment':
        return (
          <>
            <Typography.Title level={4}>
              {t(`rfp|attachmentInfo.attachmentDetails`)}
            </Typography.Title>
            {match.rfp?.assets?.map((asset, i) => (
              <Div flex="row" align="center" justify="space-between" key={i}>
                <a href={asset.url} download={asset.url}>
                  <Div flex="row" align="center" justify="space-between">
                    <FileOutlined style={{ padding: 6 }} />
                    <Typography elementTheme="body2" bold>
                      {asset.filename}
                    </Typography>
                  </Div>
                </a>
              </Div>
            ))}
          </>
        );
      case 'client':
        return (
          <Div flex="column" gap={32}>
            <RfpShortlistMessage rfpContact={rfpContact} match={match} />
            <ClientDetails projectContact={rfpContact} match={match} />
          </Div>
        );
      case 'project':
      default:
        return (
          <>
            <RfpShortlistMessage rfpContact={rfpContact} match={match} />
            <RfpDescription isPartner rfp={match.rfp} />
          </>
        );
    }
  };

  const tabs = useMemo(() => {
    const projectTabData = rfpContact ? ['client', 'project'] : ['project'];

    const hasAssets =
      match.rfp.assets && Object.keys(match.rfp.assets).length > 0;

    if (hasAssets) {
      projectTabData.push('attachment');
    }

    const tabs: TabsProps['items'] = projectTabData.map((tab, i) => {
      return {
        key: tab,
        label: (
          <Typography key={tab} elementTheme="body2">
            {t(`rfp|rfpPage.${tab}Details`)}
            {tab === 'attachment' && hasAssets && (
              <span className="count">{match.rfp.assets?.length}</span>
            )}
          </Typography>
        ),
        children: (
          <RfpDetails
            match={match}
            available={available}
            rfpContent={getProjectContent(tab)}
          />
        ),
      };
    });
    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, available]);

  return (
    <Container>
      <RfpHeader
        tabs={tabs}
        handleTabClick={handleTabClick}
        match={match}
        price={match.rfp.lead_price || 0}
        discount={match.discounted_amount || 0}
        available={available}
        activeTab={activeTab}
      />
    </Container>
  );
};

export default SingleRfp;
