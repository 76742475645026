import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import RfpReferralForm from '../../../components/referral/rfp-referral-form';
import PartnerReferralSharePage from './partner-referral-share-section';

export type RfpReferralFormValues = {
  client_name: string;
  client_email: string;
  client_phone: string;
  rfp_category: string;
  rfp_budget: string;
};

const PartnerReferralReferPage = ({ isShare }: { isShare?: boolean }) => {
  const { t } = useTranslation();

  return (
    <PageLayout
      app="provider"
      tabTitle={t('referral|pageTitle')}
      title={t('referral|form.title')}
      className={'project-referral-page-content'}
      subtitle={t('referral|form.subtitle')}
      breadcrumbs={[
        {
          breadcrumbName: t('navigation.home'),
          path: '/',
        },
        {
          breadcrumbName: t('navigation.referralRefer'),
        },
      ]}
    >
      <Container>
        <Div flex={'column'} gap={54}>
          <PartnerReferralSharePage />
          <RfpReferralForm />
        </Div>
      </Container>
    </PageLayout>
  );
};

export default PartnerReferralReferPage;
