import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import { ProfileEditModalFunctions } from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal';
import ServiceBudgetSelectionRow from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal/expertise/service-budget-selection-row';
import OnboardingFooter from '@hellodarwin/core/lib/components/onboarding/footer';
import OnboardingHeader from '@hellodarwin/core/lib/components/onboarding/header';
import {
  OnBoardingSteps,
  useOnBoarding,
} from '@hellodarwin/core/lib/context/onboarding-provider';
import { MappedTags } from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import notification from 'antd/es/notification';
import { useAppDispatch, useAppSelector } from '../../../../app/app-hooks';
import {
  createTags,
  fetchProfile,
  selectProfileLoading,
} from '../../../../features/api/slices/profile-slice';
import { usePartnerApi } from '../../../../features/api/use-partner-api';
import ServiceSelector from './service-selector';
import {
  toggleServiceTag,
  handleServiceChange,
} from 'src/features/api/slices/tags-slice';
type PartnerServicePageProps = {
  selectedTags: MappedTags;
};

const PartnerServicePage = ({ selectedTags }: PartnerServicePageProps) => {
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setCurrentStep } = useOnBoarding();

  const handleSave = async () => {
    try {
      const serviceTags = selectedTags.selectedServices;
      const specialtyTags = selectedTags.selectedSpecialties.map((t) => t.tag);

      await dispatch(createTags({ serviceTags, specialtyTags, api }));
      await dispatch(fetchProfile({ api }));
    } catch (e: any) {
      notification.error({
        message: 'Something went wrong.',
        placement: 'top',
      });
      console.error(e);
    }
    setCurrentStep(OnBoardingSteps.Specialties);
  };

  const isLoading = useAppSelector(selectProfileLoading);
  const functions: ProfileEditModalFunctions = {
    isLoading,

    toggleServiceTag: ({ service }) =>
      dispatch(toggleServiceTag({ service: service })),
    handleServiceChange({ service, minBudget, maxBudget, description }) {
      dispatch(
        handleServiceChange({ service, minBudget, maxBudget, description }),
      );
    },
  };

  const serviceBudgetSelectionRows = selectedTags.selectedServices.map(
    (tag, index) => {
      if (tag.isSelected) {
        return (
          <ServiceBudgetSelectionRow
            tag={tag}
            key={index}
            functions={functions}
          />
        );
      }

      return <></>;
    },
  );

  return (
    <PageLayout
      app="provider"
      className={'service-page-content'}
      title={t('provider-profile|expertise.service.title')}
      subtitle={t('expertise|service.subtitle')}
      footnote={t('expertise|service.footnote')}
      breadcrumbs={[]}
    >
      <OnboardingHeader />

      <Container>
        <Div>
          <ServiceSelector selectedTags={selectedTags} />
          <Div>{serviceBudgetSelectionRows}</Div>
        </Div>
      </Container>

      <OnboardingFooter
        handlePrev={() => setCurrentStep(OnBoardingSteps.Category)}
        handleNext={handleSave}
        nextDisabled={selectedTags.selectedServices.length <= 0}
      />
    </PageLayout>
  );
};

export default PartnerServicePage;
